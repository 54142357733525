"use client"

import { _tostMessage, banner_Header } from "@/services/Methods/normalMethods";
import { MouseSensor, TouchSensor, useSensor } from "@dnd-kit/core";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { WINDOW } from "@sentry/nextjs";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast, Zoom } from "react-toastify";

const UseAdminPortal = () => {
    const [uploadedFiles, setUploadedFiles] = useState([]);   /* Store Banner  From Api */
    const [inputValue, setInputValue] = useState([]);    /**  Store  imageurl from input */
    const [linkUrl, setLinkUrl] = useState("");                 /**  Store  Html or link url from input */
    const [videoUrl, setVideoUrl] = useState(null)                 /**  Store  videoUrl from input */
    const [activeId, setActiveId] = useState(null);                   /**  Store  ActiveId of banner that is going to drop to another banner */
    const [isEditting, setIsEditting] = useState(false)                /* condition used in updating inputs*/
    const [startDate, setStartDate] = useState("");                    /**  Store   BannerStartDate */
    const [endDate, setEndDate] = useState("");                         /**  Store   BannerEndDate */
    const [draggedFile, setDragedFile] = useState([]);                    /**  Store file uploaded by dragandDrop or by selecting */
    const [urlOfImage, setUrlOfImage] = useState([])                         /*Store FilePath Of DrggedFile*/
    const [statusState, setStatusState] = useState(false);                    /*Check of ToggleButton*/
    const [disableInput, setDisableInput] = useState(false);                    /*Disable  imageinput if file by drag and drop is present*/
    const [selectedFileName, setSelectedFileName] = useState(null)              /*store dropped file for dashbordPage to show selected file name  */
    const [urlToUpdate, setUrlToUpdate] = useState([])                           /*store dragged file while updating*/
    const [imageFileKey, setImageFileKey] = useState(false)                  /*check for set fileType for updating banner   (image url or image file)-keys*/
    const [startTime, setStartTime] = useState(null)                       /*startTime of banner*/
    const [endTime, setEndTime] = useState(null)                             /* endTime time of banner*/
    // ***********************************errors Fields for-Dashboard************************************************************************************************
    const [linkUrlError, setLinkUrlError] = useState("")
    const [startDateError, setStartDateError] = useState("")
    const [endDateError, setEndDateError] = useState("")
    const [imageError, setImageError] = useState("")
    const [startTimeError, setStartTimeError] = useState("")
    const [endTimeError, setEndTimeError] = useState("")
    // ******************************* Frontend-STATES*******************************************************************************************************************
    const [frontEndSettings, setFrontEndSettings] = useState("")
    const [FrontendBanners, setFrontendBanners] = useState([])
    //  **********************************GETBANNER************************************************************************************************
    const AccessToken = localStorage.getItem("access_token");
    const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `bearer ${AccessToken}`
    };
    const getBanner = async () => {
        try {
            let response = await axios.get(`${process.env.NEXT_PUBLIC_ADMINPANEL_BASE_URL}/banners/`, { headers })
            setUploadedFiles(response?.data)
            let isactive = response.data.map((item) => item.active)
            // console.log(isactive, "isactive");
            setStatusState(isactive)
        } catch (error) {
            if (error?.response?.status === 403 || 401) {
                console.log("inerror");
                _tostMessage("get")
                setTimeout(() => {
                    window.location.assign("/admin-login")
                }, 5000);
            }
            else {
                console.error(error);
            }
        }
    }
    // dynamic Banner frontend api
    const getFrontEndBanners = async () => {
        try {
            let response = await axios.get(`${process.env.NEXT_PUBLIC_ADMINPANEL_BASE_URL}tt-mw/banners`)
            setFrontendBanners(response?.data)
        } catch (error) {
            console.error("Error:", error?.message);
        }
    }
    const getFrontEndSettings = async () => {
        try {
            let response = await axios.get(`${process.env.NEXT_PUBLIC_ADMINPANEL_BASE_URL}tt-mw/settting`)
            setFrontEndSettings(response?.data)
        } catch (error) {
            console.error("Error:", error?.message);
        }
    }
    // end

    // ****************************************************FILE-DRAG & DROAP************************************************
    const onDrop = async (acceptedFiles) => {
        if (isEditting == true) {
            /*enable user to add File by selecting or dragAnddrop while updating */
            acceptedFiles.map((item) => {
                // let imageurlss = URL.createObjectURL(item)
                setUrlToUpdate(item)
                setDisableInput(false)
                setImageFileKey(true)
            })
        }
        let filename = acceptedFiles.map((item) => item.name)
        console.log(filename, 'filename', isEditting)
        setSelectedFileName(acceptedFiles)

        const newFiles = acceptedFiles.map((file) => (
            [
                URL.createObjectURL(file),
            ]
        ));
        let filetopost = acceptedFiles.map((item) => {
            // let imageurlss = URL.createObjectURL(item)
            setUrlOfImage(item)
        })
        setDisableInput(true)
        // setInputValue(urltoString)
        setDragedFile((prevFiles) => [...prevFiles, ...newFiles])
        console.log("disabledinputinhook");
    };
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.png', '.gif'],
            'video/*': ['.mp4', '.webm'],
            'application/*': ['.pdf', '.zip', '.docx', '.xlsx']
        },
    });
    // ********************************ADD-BANNER*******************************************************************************************
    const HandleInputField = async () => {
        if (validation()) {
            console.log("conditionexecute");
            let endTimes = endTime.format('HH:mm:ss')
            let starttimes = startTime.format('HH:mm:ss')
            let inputvalue = inputValue
            console.log("inputvalue", inputvalue);
            try {
                console.log(urlOfImage, "in try-catch");
                const formData = new FormData();
                formData.append('link_url', linkUrl);
                if (draggedFile) {
                    formData.append('image_file', urlOfImage);
                }
                else if (inputValue.length > 0) {
                    formData.append('image_url', inputValue);
                }
                formData.append('start_date', moment(startDate).format("YYYY-MM-DD")),
                    formData.append('end_date', moment(endDate).format('YYYY-MM-DD'));
                formData.append("start_time", starttimes)
                formData.append("end_time", endTimes)
                formData.append("video_url", videoUrl)
                console.log(formData, "formData", starttimes, endTimes);
                await axios.post(`${process.env.NEXT_PUBLIC_ADMINPANEL_BASE_URL}/banners/`, formData, { headers }).then((res) => console.log(res.data, "responseofpost"));
                _tostMessage("post")
                getBanner()
                setLinkUrl("")
                setDragedFile("")
                setStartTime(null)
                setEndTime(null)
                setSelectedFileName("")
                setStartDate("")
                setEndDate("")
                setVideoUrl("")
            } catch (error) {
                if (error?.response?.status === 403 || 401) {
                    console.log("inerror");
                    _tostMessage("get")
                    setTimeout(() => {
                        window.location.assign("/admin-login")
                    }, 5000);
                }
                else {
                    console.error(error);
                }
                console.error(error);
            }
        }
    }
    //******************************** delete banner******************************************* */
    const deleteFile = async (banner_id) => {
        try {
            await axios.delete(`${process.env.NEXT_PUBLIC_ADMINPANEL_BASE_URL}/banners/${banner_id}`, { headers })
            _tostMessage("delete")
            getBanner()
        } catch (error) {
            if (error?.response?.status === 403 || 401) {
                console.log("inerror");
                _tostMessage("get")
                setTimeout(() => {
                    window.location.assign("/admin-login")
                }, 5000);
            }
            else {
                console.error(error);
            }
        }
    };
    //********************** banner sorting******************************************** */
    const handleDragEnd = async (event) => {
        const { active, over } = event;
        if (active.id !== over?.id) {
            const oldIndex = uploadedFiles.findIndex((file) => file.id === active.id);
            const newIndex = uploadedFiles.findIndex((file) => file.id === over?.id);
            const banner1Id = active?.id
            const banner2Id = over?.id
            if (newIndex !== -1) {
                const updatedFiles = [...uploadedFiles];
                [updatedFiles[oldIndex], updatedFiles[newIndex]] = [updatedFiles[newIndex], updatedFiles[oldIndex]];

                const _SWAPING_ORDER = {
                    banner1_id: banner1Id,
                    banner2_id: banner2Id
                }
                try {
                    await axios.post(` ${process.env.NEXT_PUBLIC_ADMINPANEL_BASE_URL}/banners/swap-order?banner1_id=${banner1Id}&banner2_id=${banner2Id}`, null, { headers })
                    getBanner()
                } catch (error) {
                    console.log(error);
                }
                setUploadedFiles(updatedFiles);
                console.log(updatedFiles, "updatedFiles");
            }
            console.log("exit")
        }
        setActiveId(null);
    };
    const sensors = useSensor(
        useSensor(MouseSensor),
        useSensor(TouchSensor)
    );
    const handleLogout = () => {
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        window.location.assign("/admin-login")
    }
    const Handlecancel = () => {
        setIsEditting(false)
        setInputValue("")
        setLinkUrl("")
        setDisableInput(false)
        setDragedFile("")
        setStartTime(null)
        setEndTime(null)
        setSelectedFileName("")
        setVideoUrl("")
    }
    // ***********************VALIDATIONS************************************8
    const validation = () => {
        let isValid = true;
        if (linkUrl === "" || !linkUrl.trim()) {
            setLinkUrlError("Please Fill This Field");
            isValid = false;
        } else {
            setLinkUrlError("");
        }
        if (inputValue?.length == 0 && draggedFile?.length === 0) {
            // setImageError("Please Add banner through url or by selecting file");
            isValid = false;
        } else {
            setImageError("");
        }
        if (!startDate || startDate === "" || startDate.length === 0) {
            setStartDateError("Please Fill This Field");
            isValid = false;
        } else {
            setStartDateError("");
        }
        if (!endDate || endDate === "" || endDate.length === 0) {
            setEndDateError("Please Fill This Field");
            isValid = false;
        }
        else {
            setEndDateError("")
        }
        if (!startTime || startTime === "" || startTime === null) {
            setStartTimeError("Please Fill This Field");
            isValid = false;
        }
        else {
            setStartTimeError("")
        }
        if (!endTime || endTime === "") {
            setEndTimeError("Please Fill This Field");
            isValid = false;
        }
        else {
            setEndTimeError("")
        }
        return isValid;
    };

    return {
        uploadedFiles, setUploadedFiles, deleteFile, handleDragEnd, Handlecancel, HandleInputField, getRootProps, getInputProps, activeId, linkUrl, inputValue, startDate, endDate, setStartDate, setEndDate, sensors, setLinkUrl, setUrlOfImage, setDragedFile, draggedFile, getBanner, setInputValue, isEditting, statusState, setIsEditting, setStatusState, getBanner, disableInput, getRootProps, getInputProps, setDisableInput, startDateError, endDateError, linkUrlError, selectedFileName, validation, imageError, imageFileKey, urlToUpdate, isEditting, setIsEditting, setUrlToUpdate, setSelectedFileName, startTime, setStartTime, setEndTime, endTime, startTimeError, endTimeError, setVideoUrl, videoUrl, handleLogout, getFrontEndBanners, FrontendBanners, getFrontEndSettings, frontEndSettings
    }
}
export default UseAdminPortal