"use client";
import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import DOMPurify from 'dompurify';
import useRefferalHook from "../../../hooks/useRefferal.hook";
import { ContextData } from "@/services/Methods/normalMethods";
import Loader from "@/comman/Loader";
import UseAdminPortal from "../../../hooks/UseAdminPortal.hook";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const Home = (props) => {
  const [playVideos, setPlayVideos] = useState({});

  const data = useContext(ContextData);
  const { isReferralLoading } = useRefferalHook({ ...props, ...data }, 'urlRefferalCheck');
  const { getFrontEndBanners, FrontendBanners } = UseAdminPortal();
  useEffect(() => {
    getFrontEndBanners();
  }, []);

  const handlePlayVideo = (id) => {
    setPlayVideos((prev) => ({ ...prev, [id]: true }));
  };
  const sanitizeHtml = (html) => {
    return DOMPurify.sanitize(html, {
      ADD_TAGS: ["style"], // Allow <style> tags
      ADD_ATTR: ["class", "style"], // Allow style attributes
    });
  };

  return (
    isReferralLoading ? (
      <Loader />
    ) : (
      <div className="main main-slider-section-cstm" style={{ backgroundColor: "black" }}>
        <Swiper
          className="slider_inner"
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          navigation={true}
          pagination={{ clickable: true }}
        >
          {FrontendBanners?.map((file, index) => (
            <SwiperSlide key={file.id}>
              <div className="lg:max-w-[1920px] mx-auto z-[2] flex flex-wrap flex-row-reverse">
                <div className="video_inner lg:w-1/2 w-full flex items-center justify-center 2xl:min-h-[630px] xl:min-h-[520px] md:min-h-[590px] min-h-[320px]">
                  {file.video_url ? (
                    !playVideos[file.id] ? (
                      <div
                        className="video-thumbnail w-full h-full bg-cover bg-center relative"
                        style={{ backgroundImage: `url(${file.image_url})` }}
                        onClick={() => handlePlayVideo(file.id)}
                      >
                        <button
                          onClick={() => handlePlayVideo(file.id)}
                          className="absolute w-[150px] h-[150px] rounded-full flex items-center justify-center text-black text-2xl font-bold"
                          style={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <img src="/images/PlayArrow.png" alt="Play" />
                        </button>
                      </div>
                    ) : (
                      <figure className="lg:flex-1 block h-full max-xsm:max-w-[300px] max-xsm:mx-auto">
                        <iframe
                          src={file.video_url}
                          style={{ width: "100%", height: "100%" }}
                          allow="autoplay; fullscreen"
                          allowFullScreen
                          className="w-full h-full"
                        ></iframe>
                      </figure>
                    )
                  ) : (
                    <img
                      src={file.image_url}
                      alt="Slide"
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>

                <div
                  className="lg:flex-1 text-white lg:p-[144px_72px_144px_72px] p-[21px_20px_76px_20px] text-[28px] lg:w-1/2 relative flex flex-col lg:justify-center lg:items-start items-center slider-content-with-bg w-full"
                  style={{
                    backgroundImage: `url(${file.image_url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    className="z-[2] lg:text-start text-center"
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(file.link_url) }}
                  ></div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  );
};
export default Home;